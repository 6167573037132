#app-wrapper {
	.classic {
		margin: 5%;
		width: 90%;			
	}
}

.nonFooHead {
	.modal-header {
			border-bottom: none;
			margin-bottom: -20px;
	}	
	.modal-footer {
			border-top: none;
			margin-top: -20px;
	}	
}

.loading-overlay {
  position: fixed;
  left: 0;
  top: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
	z-index: 100000;
	opacity: 0.9;	
	background-color: white;
	text-align: center;
}

.loading-overlay-inner {
  margin-top: 20%;
}

.topBlueSlim {
	padding: 10px 8% 0 8%;
	height: 80px;
	background-image: linear-gradient(to right, #00b7ce, #bad869);
	color: #fff;
	margin: 0 0 10px 0;
}

.textFnone {
	background-size: 193px 68px;
}