.transactionRunning {
	.describe {
		margin: 0 5%;
		width: 90%;
		min-height: 200px;
		background-color: #fff;
		padding: 10px;
		color: #023266;
	}
	.functionTR {
		margin: 10px 5% 0 5%;
		width: 90%;
		min-height: 100px;
		background-color: #fff;
		padding: 10px;
		color: #023266;
		.pdfIt {
			@include float-left;
			margin-left: 20px; 
			.pdfItB {
				border-radius: 0.30rem !important;
				background-repeat: no-repeat;
				background-position: center center;
				width: 23px;
				height: 28px;
			}
		}
		.mailIt {
			@include float-right;
			margin-right: 10px;
			.mailItB {
				background-color: #00b7ce;
				border-radius: 0.30rem !important;
				color: #fff;
			}
		}
		.headIt {
			margin-bottom: 10px;
		}
	}
}

.dInfo {
	text-align: center;
	margin-top: 20px;
	.dTo {
		margin-bottom: 10px;
		display: block;
	}
}

.stopIt {
	margin-top: 20px;
}

.stopItB {
	background-color: #d61700;
	border-radius: 0.30rem !important;
	color: #fff;
}

.dTarif {
	font-size: 20px;
	font-weight: bold;
}